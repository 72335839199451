<!-- eslint-disable vue/valid-v-on -->
<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "@/app.config";
import simplebar from "simplebar-vue";
import api from "../../../../common/axios";
// import ChatForm from "./form.vue";

export default {
  page: {
    title: "Chat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    // ChatForm,
    simplebar,
  },
  data() {
    return {
      config: {
        api: "chat_admin",
      },
      filter: {
        from: 0,
        keyword: null,
      },
      perPage: 20,
      previewPath: "",
      rooms: [],
      messages: [],
      activeTab: "truck_owner",
      chatMessages: [],
      userData: {},
      userFull: {},
      model: {},
      activeTabs: 1,
    };
  },
  methods: {
    getRoom() {
      let _ = this;
      api
        .get(
          _.config.api +
            "?role=" +
            this.activeTab +
            "&orderby=last_message_at&sort=desc" +
            "&page=1" +
            "&per_page=" +
            _.perPage
        )
        .then((response) => {
          let _ = this;
          _.rooms = response.data.data.rows;
        });
    },
    getMessages(user) {
      let _ = this;
      console.log("oke");
      this.userData = user;
      api
        .get(_.config.api + "/" + user.id + "?orderby=id&sort=asc")
        .then((response) => {
          let _ = this;
          _.chatMessages = response.data.data.rows;
          _.getRoom();
        });
    },

    uploadAttachment(event) {
      let _ = this;
      if (event.target.files[0] !== undefined) {
        let extension = event.target.files[0].name.split(".").pop();
        let allowedExtention = ["jpg", "jpeg", "png"];
        if (allowedExtention.includes(extension.toLowerCase())) {
          let param = new FormData();
          param.append("file", event.target.files[0]);
          param.append("status", "active");
          param.append("title", event.target.name);
          param.append("description", "document");

          api.post("/media", param).then((response) => {
            api
              .post(_.config.api + "/" + _.userData.id, {
                file_type: "image",
                file_path: response.data.data.file,
              })
              .then(() => {
                _.getMessages(_.userData);
                document.getElementById("attachment").value = null;
              });
          });
        } else {
          this.document.isUploadError = true;
          this.document.errorUploadMessage = "file extentions not allowed";
        }
      }
    },

    filterChat() {},
    sub() {
      let _ = this;
      window.Pusher.subscribe("admin").bind("chat", function (res) {
        console.log(res);
        _.getRoom();
        _.chatOpened(res.sender_id);
      });
    },
    chatOpened(idOpned) {
      let _ = this;
      if (this.userData.id == idOpned) {
        api
          .get(_.config.api + "/" + idOpned + "?orderby=id&sort=asc")
          .then((response) => {
            let _ = this;
            _.chatMessages = response.data.data.rows;
          });
      }
    },

    prevImage(path) {
      this.previewPath = path;
      this.$refs["preview-image"].show();
    },
    saveMessage() {
      let _ = this;
      api
        .post(_.config.api + "/" + _.userData.id, _.model)
        .then(() => {
          this.model.message = null;
          _.getMessages(_.userData);
        })
        .catch((e) => {
          // console.log(e.response.data.message);
          this.responses.message = e.response.data.message;
        });
    },
    changeUrl(id) {
      let _ = this;
      if (id == 1) {
        this.activeTab = "truck_owner";
        _.chatMessages = {};
        _.getRoom();
      } else if (id == 2) {
        this.activeTab = "driver";
        _.chatMessages = {};
        _.getRoom();
      } else {
        this.activeTab = "logistic_company";
        _.chatMessages = {};
        _.getRoom();
      }
    },
  },
  mounted() {
    let _ = this;
    this.sub();
    this.senderAdminId = this.$store.state.auth.currentUser.data.id;
    var lastChar = this.$route.fullPath.substr(this.$route.fullPath.length - 1);
    if (lastChar == 1) {
      this.activeTab = "truck_owner";
      _.chatMessages = {};
      _.getRoom();
      this.activeTabs = 1;
    } else if (lastChar == 2) {
      this.activeTabs = 2;
      this.activeTab = "driver";
      _.chatMessages = {};
      _.getRoom();
    } else if (lastChar == 3) {
      this.activeTabs = 3;
      this.activeTab = "logistic_company";
      _.chatMessages = {};
      _.getRoom();
    } else {
      _.getRoom();
    }
  },
  updated() {
    let child = document.getElementsByClassName("clearfix");
    if (child && child.length) {
      child[child.length - 1].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-12">
        <div class="page-title-box">
          <h3>Chat</h3>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 mb-0">
        <b-tabs v-model="filter.from" justified nav-class="nav-tabs-custom">
          <b-tab :active="activeTabs == 1" @click="changeUrl(1)">
            <template v-slot:title>
              <p class="mb-0 font-size-13">Truck Owner</p>
            </template>
          </b-tab>
          <b-tab :active="activeTabs == 2" @click="changeUrl(2)">
            <template v-slot:title>
              <p class="mb-0 font-size-13">Truck Driver</p>
            </template>
          </b-tab>
          <b-tab :active="activeTabs == 3" @click="changeUrl(3)">
            <template v-slot:title>
              <p class="mb-0 font-size-13">LC</p>
            </template>
          </b-tab>
        </b-tabs>
        <div class="card">
          <div class="card-body" style="padding: 5px 10px 5px 10px !important">
            <b-form @submit.prevent="filterChat">
              <b-form-group label-cols="12" label-for="search-input">
                <b-form-input
                  v-model="filter.keyword"
                  type="search"
                  id="search-input"
                  placeholder="Search..."
                ></b-form-input>
              </b-form-group>
            </b-form>
            <div class="list-chat">
              <simplebar :style="`height: 480px`">
                <div
                  v-for="(i, index) in rooms"
                  :key="index"
                  class="d-flex justify-content-left cursor-pointer sender p-2"
                  @click="getMessages(i.user)"
                >
                  <a href="javascript: void(0);" class="media">
                    <img
                      class="d-flex mr-3"
                      style="border-radius: 50%; min-width: 36px"
                      :src="i.user.profile_picture"
                      alt=""
                      height="36"
                    />
                    <div class="media-body chat-user-box">
                      <p class="user-title m-0">
                        {{ i.user.full_name }}
                        <span class="text-danger">
                          {{
                            i.total_unread_message !== 0
                              ? "(" + i.total_unread_message + ")"
                              : ""
                          }}</span
                        >
                      </p>
                      <p class="text-muted">
                        <span class="float-left">
                          {{
                            i.last_message !== null
                              ? i.last_message.message
                              : ""
                          }}
                        </span>

                        <span class="float-right">
                          {{ i.last_message_at | moment("hh:mm") }}
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
              </simplebar>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <!-- <ChatForm :chat-messages="chatMessages" :user-data="userData" /> -->
        <!-- <div class="card">
          <div v-if="chatMessages.length > 0" class="card-body">
            <div
              class="d-flex align-items-center py-1 border-bottom border-primary"
            >
              <div class="position-relative">
                <img
                  :src="userData.profile_picture"
                  class="rounded-circle mr-1"
                  alt="profile"
                  width="40"
                  height="40"
                />
              </div>
              <div class="flex-grow-1 pl-2">
                <strong>{{ userData.full_name }}</strong>
              </div>
            </div>

            <div class="chat-conversation mt-4" style="min-height: 460px">
              <ul class="conversation-list">
                <simplebar
                  data-simplebar-auto-hide="false"
                  style="height: 460px"
                  id="scrollingContainer"
                >
                  <li
                    v-for="chat in chatMessages"
                    :key="chat.id"
                    :class="{ odd: chat.sender_id === senderAdminId }"
                    class="clearfix"
                  >
                    <div class="conversation-text">
                      <div class="ctext-wrap">
                        <b-img
                          v-if="chat.file_path"
                          fluid
                          style="max-height: 110px"
                          rounded
                          :src="chat.file_path"
                        />
                        <p>{{ chat.message }}</p>
                        <span class="time font-weight-light">{{
                          chat.created_at | moment("hh:mm")
                        }}</span>
                      </div>
                    </div>
                  </li>
                </simplebar>
              </ul>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(saveMessage)">
                <div class="d-flex justify-content-start mb-0">
                  <div style="width: 610px">
                    <ValidationProvider
                      name="Message"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="model.message"
                        type="text"
                        placeholder="Tulis Pesan Anda"
                      ></b-form-input>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>

                  <div class="ml-1">
                    <button
                      type="button"
                      class="btn btn-light btn-block pl-3 pr-3"
                      @click="$refs.attachment.click()"
                    >
                      <i class="mdi mdi-paperclip font-size-14"></i>
                    </button>
                    <input
                      type="file"
                      id="attachment"
                      name="attachment"
                      ref="attachment"
                      @change="uploadAttachment"
                      class="d-none"
                      accept=".jpg, .jpeg, .png"
                    />
                  </div>

                  <div class="ml-1">
                    <button
                      type="submit"
                      class="btn btn-success pl-3 pr-3"
                      :disabled="model.message == null"
                    >
                      <i class="mdi mdi-send font-size-14"></i>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>

          <div v-else class="card-body">
            <div class="chat-conversation">
              <ul class="conversation-list">
                <simplebar style="max-height: 560; min-height: 400px">
                  <h6 class="text-center">No Message Opened</h6>
                </simplebar>
              </ul>
            </div>
          </div>
        </div> -->

        <div class="card">
          <div class="card-body" v-if="chatMessages.length > 0">
            <h4 class="card-title mb-4">Chat</h4>
            <div class="chat-conversation">
              <ul class="conversation-list" style="min-height: 443px">
                <simplebar style="max-height: 460px">
                  <li
                    v-for="chat in chatMessages"
                    :key="chat.id"
                    :class="{ odd: chat.sender_id === senderAdminId }"
                    class="clearfix"
                  >
                    <div class="chat-avatar">
                      <img
                        :src="chat.sender.profile_picture"
                        alt="male"
                        class="avatar-xs rounded-circle"
                      />
                      <span class="time">{{
                        chat.created_at | moment("hh:mm")
                      }}</span>
                    </div>
                    <div class="conversation-text">
                      <div class="ctext-wrap">
                        <span class="user-name">{{
                          chat.sender.full_name
                        }}</span>
                        <b-img
                          v-if="chat.file_path"
                          width="70"
                          height="48"
                          rounded
                          :src="chat.file_path"
                          @click="prevImage(chat.file_path)"
                        />
                        <p>{{ chat.message }}</p>
                      </div>
                    </div>
                  </li>
                </simplebar>
              </ul>
            </div>

            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(saveMessage)">
                <div class="row pt-3">
                  <div
                    class="col-sm-10 col-9 chat-inputbar"
                    style="padding-right: 2px !important"
                  >
                    <input
                      id="message"
                      v-model="model.message"
                      type="text"
                      class="form-control chat-input"
                      name="message"
                      placeholder="Tulis Pesan Anda"
                    />
                  </div>

                  <div
                    class="col-sm-2 col-3 chat-send"
                    style="padding-left: 2px !important"
                  >
                    <button
                      type="button"
                      class="btn btn-light ml-1"
                      @click="$refs.attachment.click()"
                    >
                      <i class="mdi mdi-paperclip font-size-14"></i>
                    </button>
                    <input
                      type="file"
                      id="attachment"
                      name="attachment"
                      ref="attachment"
                      @change="uploadAttachment"
                      class="d-none"
                      accept=".jpg, .jpeg, .png"
                    />

                    <button
                      type="submit"
                      class="btn btn-success ml-1"
                      :disabled="model.message == null"
                    >
                      <i class="mdi mdi-send font-size-14"></i>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>

          <div v-else class="card-body">
            <div class="chat-conversation">
              <ul class="conversation-list">
                <simplebar style="max-height: 560; min-height: 400px">
                  <h6 class="text-center">No Message Opened</h6>
                </simplebar>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <b-modal
      id="preview-image"
      centered
      ref="preview-image"
      hide-title=""
      hide-footer
    >
      <div class="modal-body">
        <div class="row d-flex justify-content-center">
          <img :src="previewPath" width="500px" alt="" />
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.badge-notif {
  position: relative;
}

.badge-notif[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: 15px;
  right: 1px;
  font-size: 0.8em;
  background: #e53935;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
}
</style>
